import React from "react";
import loadable from "@loadable/component";
import { Container, Text } from "@atoms";

const Video = loadable(() => import("../atoms/Video"));

const VideoBlock = ({ caption, url }) => {
  return (
    <Container variant="sm">
      <div className="flex flex-col items-center gap-3">
        <Video url={url} />
        <Text variant="xs" className="max-w-xl text-center italic">
          {caption}
        </Text>
      </div>
    </Container>
  );
};

VideoBlock.defaultProps = {};

export default VideoBlock;
